document.addEventListener("DOMContentLoaded", function(event) {
	"use strict";
	
	// Scroll links
	var scroll_links = new SmoothScroll('[data-scroll]');	
	

	// Menu behaviour
	var nav_control = document.querySelectorAll( '.nav__control' );

	var toggleNav = function(e) {
		e.preventDefault();
		this.parentNode.classList.toggle('uncover')
	};

	if ( nav_control ) {
		for (var i = 0; i < nav_control.length; i++) {
		    nav_control[i].addEventListener('click', toggleNav, false);
		}
	}


	// Panels reveal behaviour
	var panel_control = document.querySelectorAll( '.switch' ),
		panel_close = document.querySelectorAll( '.close' ),
		panel_body, panel_related;

	var panelOpen = function(event) {
		event.preventDefault();

		// detect target panel
		panel_body = document.querySelector( this.getAttribute('data-target') );
		panel_body.classList.add('reveal');

		// detect related panel (call open panel inside another panel)
		if ( this.getAttribute('data-related') ) {
			panel_related = document.querySelector( this.getAttribute('data-related') );
			panel_related.classList.remove('reveal');
		}

		openOverlay();

		//check for new lazy images in panel
		sidebarLazyLoad.update();
	};

	var panelClose = function(event) {
		event.preventDefault();
		
		// detect target panel
		panel_body = document.querySelector( this.getAttribute('data-target') );
		panel_body.classList.remove('reveal');

		closeOverlay();
	};

	// open panel on click for all '.switch' elements
	if ( panel_control ) {
		for (var i = 0; i < panel_control.length; i++) {
		    panel_control[i].addEventListener('click', panelOpen, false);
		}
	}

	// close panel on click for all '.close' elements
	if ( panel_close ) {
		for (var i = 0; i < panel_close.length; i++) {
		    panel_close[i].addEventListener('click', panelClose, false);
		}
	}

	// overlay
	var overlay = document.querySelector('.overlay');

	var openOverlay = function() {
		overlay.classList.add('reveal');
	};

	var closeOverlay = function() {
		overlay.classList.remove('reveal');
	};

	var triggerOverlay = function() {
		var active_panel = document.querySelectorAll( '.reveal' );

		// close all open panels and hide overlay itself
		if ( active_panel ) {
			for (var i = 0; i < active_panel.length; i++) {
			    active_panel[i].classList.remove('reveal');
			}
		}
	};

	if ( overlay ) {
		overlay.addEventListener('click', triggerOverlay, false);
	}


	// Section visibility toggle
	var section_control = document.querySelectorAll( '.section-toggle' ),
		section_body;

	var sectionToggle = function(event) {
		event.preventDefault();

		section_body = document.querySelector( this.getAttribute('data-target') );
		section_body.classList.toggle('reveal');

		//check for new lazy images in hidden section
		imagesLazyLoad.update();
	};

	// open panel on click for all '.switch' elements
	if ( section_control ) {
		for (var i = 0; i < section_control.length; i++) {
		    section_control[i].addEventListener('click', sectionToggle, false);
		}
	}


	// Lazy load init
	var imagesLazyLoad = new LazyLoad({
	    elements_selector: ".lazy",
	    to_webp: true
	});

	var sidebarLazyLoad = new LazyLoad({
	    elements_selector: ".lazy-sidebar",
	     container: document.querySelector('.sidebar__wrapper'),
	    to_webp: true
	});

	// check for new lazy images in hidden tab panel 
	var tabs_link = document.querySelectorAll( '.tabs__link' );

	if ( tabs_link ) {
		for (var i = 0; i < tabs_link.length; i++) {
		    tabs_link[i].addEventListener('click', function(){
		    	sidebarLazyLoad.update();
		    }, false);
		}
	}


	// Scroll behavior for tabs component
	var scroll = new SmoothScroll();
	var tab_links = document.querySelectorAll('.component-tabs__link');

	var scrollTab = function() {
		var tab_anchor = document.querySelector('#' + this.dataset.parent);

		setTimeout(function() { 
			scroll.animateScroll(tab_anchor);
		}, 200);
	};

	if ( tab_links ) {
		for (var i = 0; i < tab_links.length; i++) {
		    tab_links[i].addEventListener('click', scrollTab, false);
		}
	}
});
